<template>
  <div>
    <content-template
      :menuName="$t('sidebar.signedUpExpert')"
      :table="true"
      :backButton="true"
      @backToPage="moveToOther('Expert Matching Dashboard')"
    >
      <template #table-header>
        <span
          :class="`s-mb-30 ${isMobile ? 's-fullWidth' : 's-flex s-mt--65'}`"
        >
          <Input
            class="s-ml-auto"
            label=""
            :placeholder="$t('general.search')"
            v-model="request.search"
            @keyup="setTime()"
            @keydown="clearTime()"
            @keydown.tab="setTime()"
            @keyup.tab="clearTime()"
            @keypress.enter="getData()"
          />
        </span>
      </template>

      <template #table>
        <TableCustom
          :columns="role_type!='admin' && role_type != 'superadmin' ? columns : columnsAdmin"
          :request="request"
          :fetchData="fetchData"
          :data="rows"
          @setSortBy="setSortBy"
          @setShow="setShow"
          @getData="getData"
          @mouseleave="popup = null"
          @togglePopup="togglePopup"
        >
          <template #index="props">
            {{ curNumber(props.index) }}
          </template>

          <template #action="props"   v-if="role_type!='admin' && role_type != 'superadmin'">  
            <div class="r-menu">
              <img
                class="r-isClickAble"
                :src="require('@/assets/icons/others/more.svg')"
                
              />
              <div
                class="r-menu-items"
                v-if="popup == curNumber(props.index)"
                @mouseleave="popup = -1"
              >
                <div
                 
                  class="r-menu-item s-center-flex"
                  @click.prevent="openVerifyDialog(true, props.row, 'accept')"
                >
                  {{ $t("general.accept") }}
                </div>
                <div
                   v-if="role_type!='admin' && role_type != 'superadmin'"
                  class="r-menu-item s-text-red s-center-flex"
                  @click.prevent="openVerifyDialog(true, props.row, 'decline')"
                >
                  {{ $t("general.reject") }}
                </div>
              </div>
            </div>
          </template>
        </TableCustom>
      </template>
    </content-template>

    <Modal v-if="dialogDelete" :title="$t('general.warning')">
      <template #modalContent>
        <p>
          {{ $t("general.areYouSureTo") }}
          {{
            verify_type == "accept"
              ? $t("general.accept").toLowerCase()
              : $t("general.reject").toLowerCase()
          }}
          {{ $t("general.thisData") }}
        </p>

        <div class="s-mtb-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click.prevent="onFinishVerif">
              {{ $t("general.continue") }}
            </Button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import tableMixins from "@/mixins/tableMixins";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [tableMixins, form],
  data() {
    return {
      verify_type: null,
      current_state: {
        status: "",
        id: "",
      },
      edited_item: {
        title: "",
      },
      columnsAdmin: [
        {
          label: "No",
          fieldName: "index",
          isSortable: false,
          class: "r-table-firstColumn",
        },
        {
          label: "expert.expertName",
          fieldName: "name",
          isSortable: true,
          class: "",
        },
        {
          label: "general.institution",
          fieldName: "institution",
          isSortable: true,
          class: "",
        },
        {
          label: "general.position",
          fieldName: "position",
          isSortable: true,
          class: "",
        },
        {
          label: "general.phoneNumber",
          fieldName: "phone_number",
          isSortable: true,
          class: "",
        },
        {
          label: "expert.rating",
          fieldName: "rating",
          isSortable: true,
          class: "",
        },
         {
          label: "Status",
          fieldName: "status",
          isSortable: true,
          class: "",
        },
      ],
      columns: [],
    };
  },
  mounted() {
    this.initData();
    // this.editItem();
  },
  computed: {
    ...mapGetters({
      rows: "expertmatching/getExpertMatching",
      role_type: "auth/getRole",
      request: "expertmatching/getRequest",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    dialogDelete() {
      return this.$store.getters["getModal"];
    },
  },
  methods: {
    ...mapActions({
      setExpertMatching: "expertmatching/setExpertMatchingStakeholderData",
      onVerifExpert: "expertmatching/onVerifExpert",
      setModal: "setModal",
    }),

    async initData() {
      await this.getData();
      //this.rows.map((obj) => ({ ...obj, open_dropdown: "false" }));
      this.columns = this.columnsAdmin.concat(
        {
          label: "general.action",
          isSortable: false,
          fieldName: "action",
          class: "r-table-actionColumn",
        },
      )
    },
    async onFinishVerif() {
      const data = {
        status: this.current_state.status,
        id: this.current_state.id,
      };
      await this.onVerifExpert(data);
      this.setExpertMatching(this.$route.params.id);
      this.setModal(false);
    },

    moveToForm() {
      this.$router.push(
        this.$translate({
          name: "New Expert Matching",
          params: { inputType: "New" },
        })
      );
    },

    async getData(type = 0) {
      this.fetchData = true;
      if (type === 0) this.request.page = 1;
      else this.request.page += type;
      await this.setExpertMatching(this.$route.params.id);
      this.fetchData = false;
      // const response = this.getResponse("BRRD");
      // if (response.status !== 1) {
      //   this.showSnackbar({
      //     type: "error",
      //     text: response.msg,
      //   });
      // }
    },

    openVerifyDialog(state, data, verify) {
      this.verify_type = verify;
      this.setModal(state);
      this.current_state.id = data.expert_responses_id;
      this.current_state.status = verify;
    },
  },
};
</script>
